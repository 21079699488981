import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Icon from '@/components/switchback/Icon/Icon';
import { ICONS, IconSize } from '@/components/switchback/Icon/Icon.constants';
import SecurityDeposit from '@/components/ui/BillModule/SecurityDeposit/SecurityDeposit';
import { TRootState } from '@/redux/rootReducer';
import { isOutdoorsyStayBookingOrQuote } from '@/redux/selectors/bill/data';
import {
  getBookingOrQuoteTotal,
  getRawBookingTotal,
  getSecurityDeposit,
} from '@/redux/selectors/bill/total';
import { getListingCanChargeSecurityDeposit, getListingData } from '@/redux/selectors/listing/page';
import { ERentalCategory } from '@/services/types/search/rentals/id';

import { CostDetailsModal } from '../CostDetailsModal/CostDetailsModalContainer';
import css from './BillModuleTotal.module.css';
import { PayLaterOffer } from './PayLaterOffer';

interface IBillModuleTotalProps {
  lastStep?: boolean;
  isPreview?: boolean;
  showPriceWithoutTaxes?: boolean;
}

export const BillModuleTotal: React.FC<IBillModuleTotalProps> = ({
  lastStep,
  isPreview,
  showPriceWithoutTaxes = false,
}) => {
  const total = useSelector((state: TRootState) =>
    getBookingOrQuoteTotal(state, showPriceWithoutTaxes),
  );
  const bookingTotal = useSelector(getRawBookingTotal);
  const securityDeposit = useSelector(getSecurityDeposit);
  const rental = useSelector(getListingData);
  const canChargeSecurityDeposit = useSelector(getListingCanChargeSecurityDeposit);
  const isOutdoorsyStay = useSelector(isOutdoorsyStayBookingOrQuote);

  const [isCostOpen, setIsCostOpen] = useState(false);

  const handleOpenCostDetails = () => setIsCostOpen(true);
  const handleCloseCostDetails = () => setIsCostOpen(false);

  return (
    <div className={`grid grid-flow-row gap-3 pt-4 ${lastStep ? '' : 'pb-8'}`}>
      <CostDetailsModal
        open={isCostOpen}
        onClose={handleCloseCostDetails}
        showPriceWithoutTaxes={showPriceWithoutTaxes}
      />

      <div
        className={`flex ${isPreview ? 'text-gray-500' : 'text-gray-900'} highlight autoType600`}>
        <p className="grow">
          <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />
        </p>
        <p className="ml-1">{total}</p>
      </div>

      <div className={`flex ${isPreview ? 'text-gray-500' : 'text-gray-800'} text-300 autoType600`}>
        <p className="grow">
          {showPriceWithoutTaxes ? (
            <FormattedMessage defaultMessage="Before taxes" id="EJyyXV" />
          ) : (
            <FormattedMessage defaultMessage="Includes est. taxes + fees" id="dveCfj" />
          )}
        </p>
        <button
          className={`flex min-w-0 ml-4 ${isPreview ? 'text-gray-500' : 'text-gray-700'} cursor-pointer`}
          onClick={handleOpenCostDetails}>
          <span className="text-right text-gray-800 text-300">
            <FormattedMessage defaultMessage="View details" id="MnpUD7" />
          </span>
          <Icon
            className="ml-1 shrink-0 transform rotate-180"
            name={ICONS.CARET_LARGE}
            size={IconSize.large}
          />
        </button>
      </div>

      {rental?.rental_category !== ERentalCategory.RV && canChargeSecurityDeposit && (
        <SecurityDeposit securityDeposit={securityDeposit} className={css.securityDeposit} />
      )}

      {(bookingTotal === null || bookingTotal > 0) && !isOutdoorsyStay && (
        <PayLaterOffer className="mt-6" />
      )}
    </div>
  );
};
