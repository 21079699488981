import { ActiveOptimizelyDecision } from '.';

// Refine optimizely variable into a module order variable

// Default to existing legacy order in case we can't find certain orders
export const getInsuranceBundleSelectionFromOptimizely = (
  decision?: ActiveOptimizelyDecision | null,
): string | null => {
  // In case optimizely is down, hardcode the legacy order

  if (!decision) return null;
  const { variables } = decision;
  if (decision.variationKey?.toLowerCase() === 'control') return null;

  if (!variables) return null;
  // Check if variables is a string and validate its value
  if (typeof variables.defaultBundle === 'string') {
    const lowerCaseVariable = variables.defaultBundle.toLowerCase();
    if (
      lowerCaseVariable === 'preferred' ||
      lowerCaseVariable === 'peace of mind' ||
      lowerCaseVariable === 'essential'
    ) {
      return variables.defaultBundle; // Return the valid variable
    }
    return 'Preferred'; // Default to Preferred if valid but not one of the specified
  }

  return null; // Return null if variables is not a string
};
