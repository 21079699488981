import {
  Accordion,
  ELinkVariant,
  ETextStyleVariant,
  GeneralCancelIcon,
  GeneralCheckCircleIcon,
  Link,
  Selector,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import IMGLogoSVG from '@/assets/protection/img/img-logo.svg';
import { ServiceIntegration } from '@/graphql/types-and-hooks';
import { getBookingOrQuote } from '@/redux/selectors/bill/data';
import { IBookingService } from '@/services/types/booking/services';
import { formatCurrency } from '@/utility/currency';

interface IProtectionModalProps {
  availableTripInsuranceService?: ServiceIntegration;
  addedTripInsuranceService?: IBookingService;
  tripInsuranceServiceIsAdded?: boolean;
  onChange?: (protection: string, selected?: boolean) => void;
}

export const TripInsuranceSelector: React.FC<IProtectionModalProps> = ({
  availableTripInsuranceService,
  addedTripInsuranceService,
  tripInsuranceServiceIsAdded,
  onChange,
}) => {
  const intl = useIntl();

  const bookingOrQuote = useSelector(getBookingOrQuote);
  const [addTripProtection, setAddTripProtection] = useState<boolean | undefined>(
    tripInsuranceServiceIsAdded,
  );

  const [openSections, setOpenSections] = useState<string[]>([]);

  const tripInsurancePrice = formatCurrency({
    priceInCents: (addedTripInsuranceService || availableTripInsuranceService)?.total || 0,
    currency: bookingOrQuote?.presentment_currency || 'USD',
    digits: 2,
  });

  const learnMoreTitle = intl.formatMessage({ defaultMessage: 'Learn more', id: 'TdTXXf' });

  const onOpenSectionsChange = () => {
    setOpenSections(prev => {
      const currentSections = prev ?? [];
      return [...(currentSections?.length === 0 ? [learnMoreTitle] : [])];
    });
  };

  const onProtectionChange = (protection: 'trip') => {
    setAddTripProtection(!addTripProtection);
    onChange?.(protection);
    if (!addTripProtection) {
      setOpenSections([learnMoreTitle]);
    } else {
      setOpenSections([]);
    }
  };

  return (
    <>
      <div>
        <Selector
          isSelected={addTripProtection === true}
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Trip insurance selector',
            id: 'MLysAH',
          })}
          icon={GeneralCancelIcon}
          label={intl.formatMessage(
            {
              defaultMessage: 'Cancellation & more for {tripInsurancePrice}',
              id: 'biFpDk',
            },
            { tripInsurancePrice },
          )}
          name="add-trip-protection"
          onChange={() => onProtectionChange('trip')}
          description={intl.formatMessage({
            defaultMessage:
              'Even the best-planned trips can be affected by an unexpected event. Get reimbursed up to 100% due to a covered reason.',
            id: 'gB+a2d',
          })}
          value="add-trip-protection"
          withBorder
          showIndicator
        />
        <Accordion
          className="w-full"
          openSections={openSections}
          onOpenSectionsChange={onOpenSectionsChange}>
          <Accordion.Item divider title={learnMoreTitle} className="p-0">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center w-full bg-[#2C415F] gap-2">
                <Text variant={ETextStyleVariant.LegalRegular} className="text-white">
                  <FormattedMessage defaultMessage="Powered by" id="U8QBHO" />
                </Text>
                <IMGLogoSVG height={88} />
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <GeneralCheckCircleIcon className="text-green-500" />
                </div>
                <Text className="text-gray-800">
                  <FormattedMessage
                    defaultMessage="Get reimbursed up to 100% of the non-refundable costs of your booking, in case of a trip cancellation or interruption due to a covered reason."
                    id="rvD2mE"
                  />
                </Text>
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <GeneralCheckCircleIcon className="text-green-500" />
                </div>
                <Text className="text-gray-800">
                  <FormattedMessage
                    defaultMessage="Get reimbursed for the purchase of personal items, if baggage is delayed 24 hours or more."
                    id="YtTmvm"
                  />
                </Text>
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <GeneralCheckCircleIcon className="text-green-500" />
                </div>
                <Text className="text-gray-800">
                  <FormattedMessage
                    defaultMessage="24/7 emergency transportation to get you to a medical facility when you need care right away."
                    id="P2P0ul"
                  />
                </Text>
              </div>
              <div>
                <Link
                  variant={ELinkVariant.Underline}
                  href="/help/travel-insurance-work"
                  target="_blank">
                  <Text className="text-gray-800">
                    <FormattedMessage
                      defaultMessage="Learn more about Trip protection"
                      id="ShtHr3"
                    />
                  </Text>
                </Link>
              </div>
              <div className="pb-2">
                <Text variant={ETextStyleVariant.SmallRegular}>
                  <FormattedMessage
                    defaultMessage="Available to U.S. residents only. Add cancellation protection until the day of your trip. Cancel within 10 days after purchase."
                    id="sCZCu+"
                  />
                </Text>
              </div>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};
