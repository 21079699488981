import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import Modal from '@/components/switchback/Modal';
import { ModalSize } from '@/components/switchback/Modal/Modal';
import Text from '@/components/switchback/Text/Text';
import DeductibleTable from '@/components/trips/ChangeInsuranceModal/DeductibleTable';
import { TCurrency } from '@/config/locales';
import { useInsuranceBundleFeatures } from '@/constants/insuranceBundles';
import { getIsStationaryCamperCampsite } from '@/redux/selectors/listing/campsite';
import { getListingLocation } from '@/redux/selectors/listing/page';
import { IUserNoticeEvent } from '@/services/types/booking/details';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';

import InsuranceModalFooter from './InsuranceModalFooter';
import UserNoticeEvent from './UserNoticeEvent';

interface InsuranceModalProps {
  selectedBundleId?: string;
  insuranceBundles: IInsuranceBundle[];
  currency: TCurrency;
  open: boolean;
  onClose: () => void;
  onSubmit: (bundleId: string) => void;
  stationaryDelivery: boolean;
  isLoading?: boolean;
  userNoticeEvents?: IUserNoticeEvent[];
}

const InsuranceModal: React.FC<InsuranceModalProps> = ({
  selectedBundleId,
  insuranceBundles,
  currency,
  open,
  onClose,
  onSubmit,
  stationaryDelivery,
  isLoading,
  userNoticeEvents,
}) => {
  const intl = useIntl();
  const listingLocation = useSelector(getListingLocation);
  const isStationaryCamperCampsite = useSelector(getIsStationaryCamperCampsite);
  const insuranceBundleFeatures = useInsuranceBundleFeatures(
    stationaryDelivery,
    listingLocation?.state === 'NY',
  );
  const [selectedInsuranceBundleId, setSelectedInsuranceBundleId] = useState(selectedBundleId);
  const modalTitle = isStationaryCamperCampsite
    ? intl.formatMessage({
        defaultMessage: 'Your insurance coverage',
        id: 'xcUBMI',
      })
    : intl.formatMessage({
        defaultMessage: 'Choose your insurance coverage',
        id: '0s1hN8',
      });

  const handleSubmit = () => {
    if (!stationaryDelivery && selectedInsuranceBundleId && onSubmit) {
      onSubmit(selectedInsuranceBundleId);
    }

    onClose();
  };

  useEffect(() => {
    if (open && selectedBundleId) {
      setSelectedInsuranceBundleId(selectedBundleId);
    }
  }, [open, selectedBundleId]);

  return (
    <Modal
      className="[&>[role=dialog]]:md:max-w-[720px]"
      open={open}
      size={ModalSize.large}
      onClose={onClose}
      title={modalTitle}
      footer={
        !isStationaryCamperCampsite ? (
          <InsuranceModalFooter isLoading={isLoading} onSubmit={handleSubmit} />
        ) : null
      }>
      {userNoticeEvents?.map(event => <UserNoticeEvent event={event} key={event.id} />)}

      <DeductibleTable
        allPlansInfo={
          <>
            <Text type="paragraph" className="mb-2">
              <FormattedMessage defaultMessage="Included:" id="kJevit" />
            </Text>
            <ul className="list-disc list-inside">
              {insuranceBundleFeatures.map(bundleFeature => (
                <li key={bundleFeature.name}>
                  <span>{bundleFeature.name}</span>
                </li>
              ))}
            </ul>
          </>
        }
        insuranceBundles={insuranceBundles}
        selectedBundleId={selectedInsuranceBundleId}
        onSelectedBundleChange={bundle => setSelectedInsuranceBundleId(bundle.id)}
        currency={currency}
        isStationary={stationaryDelivery}
      />
      <div className="mt-6 mb-6 md:mb-2">
        <Link
          data-testid="insurance-info-link"
          href="/help/outdoorsys-protection-packages"
          target="_blank">
          <FormattedMessage
            defaultMessage="Learn more about insurance"
            id="ycO1Nv"
            description="UI > Insurance Modal > Insurance Modal Footer"
          />
          <Icon width={10} className={'transform rotate-180'} name={CARET_LARGE} />
        </Link>
      </div>
    </Modal>
  );
};

export default InsuranceModal;
